import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const UpperContent = ({ upperContent }) => {

  return (
    <div className="project-start-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2>{upperContent.title}</h2>
              <p>{upperContent.description}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="project-start-image">
              <GatsbyImage
                alt={upperContent.title}
                image={upperContent.image.childImageSharp.gatsbyImageData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpperContent
