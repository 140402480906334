import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const WorkFlow = ({ workFlow }) => {
  return (
    <section className="solutions-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <h2>{workFlow.title}</h2>
        </div>

        <div className="row justify-content-center mt-7">
          <div className="col-12 text-center">
            <GatsbyImage
              alt={workFlow.title}
              image={workFlow.image.childImageSharp.gatsbyImageData}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default WorkFlow
