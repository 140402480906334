import React from 'react'
import Layout from '../components/App/Layout'
import Navbar from '../components/Common/Navbar'
import PageBanner from '../components/Common/PageBanner'
import Footer from '../components/Common/Footer'
import UpperContent from '../components/HowWeWork/UpperContent'
import OurProcess from '../components/HowWeWork/OurProcess'
import AgileMindSet from '../components/HowWeWork/AgileMindSet'
import ServiceOverview from '../components/HowWeWork/ServiceOverview'
import WorkFlow from '../components/HowWeWork/WorkFlow'
import NeedHelp from '../components/NeedHelp/NeedHelp'
import { graphql, useStaticQuery } from 'gatsby'

export const query = graphql`
  {
    strapiSeos(slug: { eq: "how-we-work" }) {
      Meta {
        title
        description
        keywords
      }
      TwitterMeta {
        card
      }
    }
    strapiHowWeWork {
      upperContent {
        title
        description
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      timeline {
        title
        timelineCollection {
          title
          description
          icon
        }
      }
      agileMindset {
        content
      }
      serviceOverview {
        title
        description
        serviceOverviewCollection {
          title
          description
          icon
        }
      }
      workFlow {
        title
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

const HowWeWork = () => {
  const data = useStaticQuery(query)
  const {
    strapiSeos: { Meta: metaTags, TwitterMeta: twitterCard },
  } = data

  const {
    strapiHowWeWork: {
      upperContent,
      timeline,
      agileMindset,
      serviceOverview,
      workFlow,
    },
  } = data

  return (
    <Layout metaTags={metaTags} twitterCard={twitterCard}>
      <Navbar />

      <PageBanner
        pageTitle="How We Work"
        homePageText="Home"
        homePageUrl="/"
        activePageText="How We Work"
      />

      <UpperContent upperContent={upperContent} />
      <OurProcess timeline={timeline} />
      <AgileMindSet agileMindset={agileMindset} />
      <ServiceOverview serviceOverview={serviceOverview} />
      <WorkFlow workFlow={workFlow} />
      <NeedHelp />

      <Footer />
    </Layout>
  )
}

export default HowWeWork
