import React from 'react'
import ReactMarkdown from 'react-markdown'

const ServiceOverview = ({ serviceOverview }) => {

  return (
    <section className="service-details-area pt-100 pb-70 bg-fffbf5">
      <div className="container">
          <div className="row">
            <div className="col">
              <div className="service-details-desc">
                <h1>{serviceOverview.title}</h1>
                <ReactMarkdown source={serviceOverview.description} />
              </div>
            </div>
          </div>

          {serviceOverview.serviceOverviewCollection.map((service, index) => (
            <div className="row pt-70 service-sub-item" key={index}>
              <div className="col-sm-2 col-12 text-center">
                <i className={`${service.icon} icon pb-70`} />
              </div>
              <div className="col-sm-8 col-12">
                <h2>{service.title}</h2>
                <ReactMarkdown source={service.description} />
              </div>
            </div>
          ))}
        </div>
    </section>
  )
}

export default ServiceOverview
