import React from 'react'

const OurProcess = ({ timeline }) => {
  return (
    <div className="section section-md pt-100 pb-70 bg-e3fbff">
      <div className="container">
        <div className="row">
          <div className="col-md-4 text-center mx-auto">
            <div className="section-title">
              <h2>{timeline.title}</h2>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-10 mx-auto">
            <div className="timeline timeline-one">
              {timeline.timelineCollection.map((timeline, index) => {
                return (
                  <div className="timeline-item" key={index}>
                    <span className="our-process icon icon-info icon-lg">
                      <i className={`${timeline.icon} icon`} />
                    </span>
                    <h5 className="my-3">{timeline.title}</h5>
                    <p>{timeline.description}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurProcess
