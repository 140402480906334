import React from 'react'
import ReactMarkdown from 'react-markdown'

const AgileMindSet = ({ agileMindset }) => {
  return (
    <section className="pt-100 pb-70">
      <div className="container">
        <div className="row col-12">
          <div className="content">
            <ReactMarkdown source={agileMindset.content} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default AgileMindSet
